const faders = document.querySelectorAll(".fade-in");
const sliders = document.querySelectorAll(".slide-in");

const appearOptions = { threshold: 0, rootMargin: "0px 0px -150px 0px" };
const appearOnScroll = new IntersectionObserver(function (
  entries,
  appearOnScroll
) {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      return;
    } else {
      entry.target.classList.add("appear");
      appearOnScroll.unobserve(entry.target);
    }
  });
},
appearOptions);

faders.forEach((fader) => {
  appearOnScroll.observe(fader);
});

sliders.forEach((slider) => {
  appearOnScroll.observe(slider);
});

// Partner
const left = document.querySelector(".left");
const right = document.querySelector(".right");
const section = document.querySelector(".section-partners");

left.addEventListener("mouseenter", () => {
  section.classList.add("hover-left");
});

left.addEventListener("mouseleave", () => {
  section.classList.remove("hover-left");
});

right.addEventListener("mouseenter", () => {
  section.classList.add("hover-right");
});

right.addEventListener("mouseleave", () => {
  section.classList.remove("hover-right");
});
